import { render, staticRenderFns } from "./RiscoCredito.vue?vue&type=template&id=460b03f0&scoped=true&"
import script from "./RiscoCredito.vue?vue&type=script&lang=js&"
export * from "./RiscoCredito.vue?vue&type=script&lang=js&"
import style0 from "./RiscoCredito.vue?vue&type=style&index=0&id=460b03f0&prod&lang=scss&scoped=true&"
import style1 from "@/styles/components/superbox_customizado.scss?vue&type=style&index=1&id=460b03f0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "460b03f0",
  null
  
)

export default component.exports