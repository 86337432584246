<template>
  <div class="box-valor horiz">
    <span class="valor-titulo">Estabilidade Financeira</span>
    <span class="valor-icones">
      <span
        v-for="(valor, index) in 5"
        :key="index"
        class="valores"
        :class="{
          margin: index != 4,
        }"
      >
        <span
          class="icon"
          :class="{
            'material-icons': index < ratingEstab,
            'material-symbols-outlined': index >= ratingEstab,
            nulo: valorEstab == '-',
          }"
        >
          star
        </span>
        <span class="valor" v-if="tipoExibidoDelay != 'documento'">
          {{ getPorcentagem(index) }}
        </span>
      </span>
    </span>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapWritableState, mapActions } from "pinia";

export default {
  name: "EstabilidadeFinanceira",

  components: {},

  props: {
    tipoExibido: String,
    resultados: Object,
  },

  data() {
    return {
      tipoExibidoDelay: this.tipoExibido,
    };
  },
  watch: {
    tipoExibido(newVal, oldVal) {
      setTimeout(() => {
        this.tipoExibidoDelay = newVal;
      }, 500);
    },
  },

  methods: {
    getPorcentagem(i) {
      if (Object.keys(this.resultados).length !== 0) {
        const atributo = `bk_111_001_num_${
          this.tipoExibidoDelay
        }_estrelas_estabilidade_financeira_proporcao_0${i + 1}`;
        return this.resultados[atributo] != "-"
          ? this.resultados[atributo] + "%"
          : this.resultados[atributo];
      }
    },
  },

  created() {},

  computed: {
    ratingEstab() {
      if (this.tipoExibidoDelay == "documento") {
        var valor =
          this.resultados[
            `boxauxsuppf002_qtd_documento_estrelas_estabilidade_financeira`
          ];
      } else {
        var valor =
          this.resultados[
            `bk_111_001_qtd_${this.tipoExibidoDelay}_estrelas_estabilidade_financeira`
          ];
      }
      if (valor != undefined && valor != "-" && valor != null) {
        return valor;
      } else {
        return 0;
      }
    },

    valorEstab() {
      if (this.tipoExibidoDelay == "documento") {
        return this.resultados[
          `boxauxsuppf002_qtd_documento_estrelas_estabilidade_financeira`
        ];
      } else {
        return this.resultados[
          `bk_111_001_qtd_${this.tipoExibidoDelay}_estrelas_estabilidade_financeira`
        ];
      }
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.box-valor {
  padding: 5px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 18px;
  border-radius: 0 0 10px 10px;
  z-index: -1;
  background-color: white;
  max-height: 95px;
  cursor: pointer;

  &.horiz {
    padding: 5px;
    background-color: white;
    border-radius: 0 10px 10px 0;
    flex-direction: column;
    height: 100%;
    justify-content: flex-start;
  }
}

.valores {
  display: flex;
  flex-direction: column;
}

.valor {
  line-height: 14px;
  text-align: center;
  font-size: 11px;
}

.valor-icones {
  display: flex;
  align-items: center;
  justify-content: center;

  .icon {
    color: #a69bff;
    height: 21px;

    &.nulo {
      color: #b8b8b8;
    }
  }
}

.margin {
  margin-right: 7px;
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
