<template>
  <div id="appPrint" class="boxResult">
    <div class="box-button">
      <button @click="generatePDF()" class="pdfButton">Gerar PDF</button>
      <button @click="novaConsulta()" class="pdfButton consulta">Nova consulta</button>
    </div>
    <div class="spinnerBG" v-if="liberaSpinner || resultados.Consultas == undefined">
      <Loading class="spinner" />
    </div>

    <div
      class="contentFull"
      id="contentsb"
      slot="pdf-content"
      ref="pdfContent"
      v-if="resultados.Consultas != undefined"
    >
      <div class="header" ref="blocosHeader">
        <span class="icons-header"> </span>
        <h1 class="header-title">Superbox - Pessoa Física</h1>
      </div>
      <div class="body" ref="blocosStatus">
        <div class="cracha">
          <div class="col">
            <div class="info-cracha">
              <span class="info-title">CPF:</span>
              <span class="info-res">{{ valorCPF }}</span>
            </div>
            <div class="info-cracha">
              <span class="info-title"> Nome completo: </span>
              <span class="info-res">{{ nomeCompleto }}</span>
            </div>
            <div class="info-cracha">
              <span class="info-title" :id="'ResultadosSuperBox-0'">
                Data e hora da consulta:
              </span>
              <span class="info-res">{{ data }} | {{ hora }}</span>
            </div>
            <div class="info-cracha">
              <span class="info-title" :id="'ResultadosSuperBox-1'">
                Código da consulta:</span
              >
              <span class="info-res">{{ resultados.CodigoAgrupador }}</span>
            </div>
            <div
              class="info-cracha data_ref"
              v-if="valorDataRef != '' && valorDataRef != data"
            >
              <span class="info-title data_ref"> Data de referência: </span>
              <span class="info-res">{{ valorDataRef }}</span>
            </div>
            <img class="img-logo-x" :src="logoX" />
          </div>
          <div class="col">
            <img class="img-logo" :src="logoSB" />
          </div>
        </div>
        <div class="info-blocos">
          <BlocoStatus
            v-for="(bloco, index) in listaBlocos"
            @scroll-to-block="scrollToBlock"
            :key="index"
            :bloco="bloco"
            :index="index"
            :status="blocoStatus"
            :exibirTootipsConsultas="exibirTootipsConsultas"
          />
        </div>
      </div>

      <BlocoPFDadosCadastrais
        ref="blocoDadosCadastrais"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :resultados="resultadosDadosCadastrais"
        :valorCPF="valorCPF"
        :nomeCompleto="nomeCompleto"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFSonar
        ref="blocoSonarOutbox"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :gerandoPdf="gerandoPdf"
        :resultados="resultadoSonar"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFRenda
        ref="blocoRendaPresumida"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :resultados="resultadoRendaPresumida"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFAssociacoesPessoais
        ref="blocoAssociaçõesPessoais"
        :resultados="resultadoAssociacoesPessoais"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFGeolocalidades
        ref="blocoEndereçoseGeolocalidades"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :resultados="resultadosGeolocalidades"
        :parametros="resultadosGeolocalidadesParametros"
        :codigo_agrupador="resultados.CodigoAgrupador"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFMapaRiscos
        ref="blocoMapaGeraldeRiscos"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :resultados="resultadosMapaRiscos"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFPassagens
        ref="blocoHistóricodePassagens"
        :resultados="resultadoHistoricoDePassagens"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFConexoesEmpresas
        ref="blocoConexõescomEmpresas"
        :listaBlocos="listaBlocos"
        :consultaErros="consultaErros"
        :gerandoPdf="gerandoPdf"
        :resultados="resultadosConexoesEmpresas"
        :resultadosFaturamento="resultadosFaturamentoPresumido"
        :codigo_agrupador="resultados.CodigoAgrupador"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
      />

      <BlocoPFAlertas
        ref="blocoAlertasGerais"
        :listaBlocos="listaBlocos"
        :exibirTootipsConsultas="exibirTootipsConsultas"
        @alteraBloco="alteraBloco"
        @scrollToBlockAlerta="scrollToBlockAlerta"
      />

      <PrefTooltipTela @recebePrefTooltips="recebePrefTooltips" />

      <MenuLateralSuperbox
        @scroll-to-block="scrollToBlock"
        :listaBlocos="listaBlocos"
        :status="blocoStatus"
        :refAtual="refAtual"
      />

      <div v-for="(tt, i) in tooltipsBloco0" :key="i">
        <b-tooltip
          v-if="exibirTootipsConsultas"
          :target="tt.target"
          triggers="hover"
          placement="bottom"
          variant="dark"
          class="tooltipTexts"
          boundary="window"
        >
          <div v-html="tt.texto"></div>
        </b-tooltip>
      </div>
      <div class="footer-sup">
        <div class="footer-text">
          Clique<span class="link" @click="abrirContato">aqui</span>
          para reportar um problema com os dados desta página
          <span class="material-symbols-outlined question"> question_mark </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { userPreferenceStore } from "@/stores/userPreference";
import { produtosStore } from "@/stores/produtos";
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { chatOutboxStore } from "@/stores/chat-Outbox.js";

import { mapWritableState, mapActions } from "pinia";
import { mascaras } from "@/helpers/mascaras";
import { createPDFZip, createPDF } from "@/services/planilha.service.js";

import produtosSuperBox from "@/helpers/produtos_superbox.json";
import BlocoStatus from "@/components/SuperBox/BlocosStatus.vue";
import BlocoPFDadosCadastrais from "@/components/SuperBox/BlocoPFDadosCadastrais.vue";
import BlocoPFAssociacoesPessoais from "@/components/SuperBox/BlocoPFAssociacoesPessoais.vue";
import BlocoPFGeolocalidades from "@/components/SuperBox/BlocoPFGeolocalidades.vue";
import BlocoPFSonar from "@/components/SuperBox/BlocoPFSonar.vue";
import BlocoPFMapaRiscos from "@/components/SuperBox/BlocoPFMapaRiscos.vue";
import BlocoPFPassagens from "@/components/SuperBox/BlocoPFPassagens.vue";
import BlocoPFConexoesEmpresas from "@/components/SuperBox/BlocoPFConexoesEmpresas.vue";
import BlocoPFRenda from "@/components/SuperBox/BlocoPFRenda.vue";
import BlocoPFAlertas from "@/components/SuperBox/BlocoPFAlertas.vue";
import listaTooltips from "@/helpers/lista_tooltips_superbox.json";
import Loading from "@/components/Loading";
import PrefTooltipTela from "@/pages/MinhaConta/PrefTooltipTela.vue";
import MenuLateralSuperbox from "@/components/SuperBox/MenuLateralSuperbox.vue";

export default {
  name: "ResultadosSuperBoxHistorico",

  components: {
    BlocoStatus,
    BlocoPFDadosCadastrais,
    BlocoPFAssociacoesPessoais,
    BlocoPFGeolocalidades,
    BlocoPFSonar,
    BlocoPFMapaRiscos,
    BlocoPFPassagens,
    BlocoPFConexoesEmpresas,
    BlocoPFRenda,
    BlocoPFAlertas,
    Loading,
    PrefTooltipTela,
    MenuLateralSuperbox,
  },

  data() {
    return {
      logo: "/img/outbox_logo_azul.png",
      fundo: "/img/superbox/fundo.png",
      logoSB: "/img/superbox/logo-superbox.png",
      logoX: "/img/superbox/logoX.png",
      blocoStatus: new Array(9).fill("loading"),
      liberaSpinner: false,
      gerandoPdf: false,
      codigo_agrupador: "",
      consultaErros: {},

      refAtual: "",
      refsList: [],
      observer: null,
    };
  },

  props: {
    resultados: Object,
  },

  watch: {
    // resultados: {
    //   handler(newVal) {
    //     if (newVal) {
    //       this.carregaResumoResultadoSuperbox(newVal.Consultas);
    //     }
    //   },
    //   immediate: true,
    //   deep: true,
    // },
  },

  methods: {
    // carregaResumoResultadoSuperbox(consultas) {
    //   consultas.forEach((consulta) => {
    //     const obj = consulta.Consulta.atributos;
    //     this.recebeResultadoSuperbox(obj);
    //   });
    // },

    async controlaBlocos() {
      this.$nextTick(() => {
        const lengthConsultas = this.resultados.Consultas.length;
        for (let i = 0; i < lengthConsultas; i++) {
          for (let j = 0; j < this.listaBlocos.length; j++) {
            if (this.resultados.Consultas[i].Consulta.atributos != null) {
              const descricao = this.resultados.Consultas[i].Consulta.atributos.descricao;
              if (this.compararValores(this.listaBlocos[j].descricao, descricao)) {
                const index = this.listaBlocos[j].id - 1;
                this.$set(this.blocoStatus, index, "ok");
                const descricaoBloco = this.listaBlocos[j].descricao.replace(/\s/g, "");
                this.$refs[`bloco${descricaoBloco}`].abreEsteBloco();
              }
            }

            if (
              this.resultados.Consultas[i].Consulta.atributos == null &&
              this.resultados.Consultas[i].Consulta.erro != null
            ) {
              const ref = this.resultados.Consultas[i].Consulta.sumario_consulta
                .parametros.cod_ref;

              const erro = this.resultados.Consultas[i].Consulta.erro.erro;

              this.$set(this.consultaErros, ref, erro);
              const bloco = this.listaBlocos.find((obj) => obj.box === ref);

              if (bloco) {
                var index = bloco.id - 1;
                this.$set(this.blocoStatus, index, "erro");
              }
            }

            this.$refs[`blocoAlertasGerais`].abreEsteBloco();
            this.$set(this.blocoStatus, 8, "ok");
          }
        }

        this.codigo_agrupador = this.resultados.CodigoAgrupador;

        const contexto = {
          codigo_agrupador: this.codigo_agrupador,
          rota: this.$route.path,
          nome: this.$route.name,
          descricao: "",
        };

        this.recebeContexto(contexto);

        this.liberaSpinner = false;
      });
    },

    initRefsList() {
      this.refsList = Object.keys(this.$refs).map((refName) => this.$refs[refName]);
    },

    initObserver() {
      const options = {
        root: null,
        rootMargin: "-50% 0px -50% 0px",
        threshold: 0,
      };

      this.observer = new IntersectionObserver(this.handleIntersection, options);

      this.refsList.forEach((ref) => {
        if (ref && ref.$el) {
          this.observer.observe(ref.$el);
        }
      });
    },

    handleIntersection(entries) {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const refName = Object.keys(this.$refs).find((refName) => {
            return this.$refs[refName].$el === entry.target;
          });

          if (refName) {
            this.refAtual = refName;
          }
        }
      });
    },

    abrirContato() {
      const novaUrl = this.$router.resolve({
        path: "/suporte/contato",
        query: { source: "consulta" },
      }).href;
      window.open(novaUrl, "_blank");
    },

    compararValores(str1, str2) {
      const removerAcentos = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

      const prepararString = (str) => {
        return str.toLowerCase();
      };

      if (str1 != undefined && str2 != undefined) {
        const str1Preparada = prepararString(removerAcentos(str1));
        const str2Preparada = prepararString(removerAcentos(str2));
        return str2Preparada.includes(str1Preparada);
      } else {
        return false;
      }
    },

    scrollToBlock(index, bloco) {
      if (this.blocoStatus[index] != "loading") {
        const descricaoBloco = bloco.descricao.replace(/\s/g, "");
        const blockElement = this.$refs[`bloco${descricaoBloco}`].$el;
        if (blockElement) {
          blockElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    },

    scrollToBlockAlerta(bloco) {
      const blockElement = this.$refs[bloco].$el;
      if (blockElement) {
        blockElement.scrollIntoView({ behavior: "smooth" });
      }
    },

    // abreTodos() {
    //   const lengthConsultas = this.resultados.Consultas.length;
    //   for (let i = 0; i < lengthConsultas; i++) {
    //     this.$refs[`blocopf${i}`].abreEsteBloco();
    //   }
    // },

    async generatePDF() {
      this.gerandoPdf = true;
      await this.controlaBlocos();
      this.liberaSpinner = true;
      await this.viewMapsAndReturn();
      const targetElement = this.$refs.pdfContent;

      const codAgr = this.resultados.CodigoAgrupador;
      let nomeArquivo = `outbox-superboxpf-${codAgr}`;

      if (this.tipoDownloadConsulta == "zip") {
        createPDFZip(targetElement, nomeArquivo);
      } else {
        createPDF(targetElement, nomeArquivo);
      }

      setTimeout(() => {
        this.liberaSpinner = false;
        this.gerandoPdf = false;
      }, 3000);
    },

    async viewMapsAndReturn() {
      const maps = document.getElementsByClassName("vue-map-container");
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));

      for (const map of maps) {
        map.scrollIntoView();
        await delay(2000);
      }
      window.scrollTo({
        top: 0,
        left: 0,
      });
    },

    novaConsulta() {
      this.$router.push("/consultas/superbox/0");
    },

    recebePrefTooltips(pref) {
      this.exibirTootipsConsultas = pref;
    },

    formataData(data) {
      return data.slice(0, 10).split("-").reverse().join("/");
    },

    ...mapActions(userPreferenceStore, [
      "syncPreferenciaOcultarDados",
      "syncPreferenciaConsultasTooltips",
      "syncPreferenciaTipoDownload",
    ]),

    ...mapActions(blocosSuperBoxStore, ["alteraBloco", "abreTodosBlocos"]),

    ...mapActions(chatOutboxStore, ["recebeContexto"]),
  },

  computed: {
    resultadosDadosCadastrais() {
      const consultaEncontrada = this.resultados.Consultas.find((consulta) => {
        return (
          consulta.Consulta &&
          consulta.Consulta.atributos &&
          consulta.Consulta.atributos.descricao.includes("Dados Cadastrais")
        );
      });

      return consultaEncontrada ? consultaEncontrada.Consulta.atributos : {};
    },

    resultadoRendaPresumida() {
      const consultaEncontrada = this.resultados.Consultas.find((consulta) => {
        return (
          consulta.Consulta &&
          consulta.Consulta.atributos &&
          consulta.Consulta.atributos.descricao.includes("Renda presumida")
        );
      });

      return consultaEncontrada ? consultaEncontrada.Consulta.atributos : {};
    },
    resultadoSonar() {
      const consultaEncontrada = this.resultados.Consultas.find((consulta) => {
        return (
          consulta.Consulta &&
          consulta.Consulta.atributos &&
          consulta.Consulta.atributos.descricao.includes("Sonar Outbox")
        );
      });

      return consultaEncontrada ? consultaEncontrada.Consulta.atributos : {};
    },

    resultadoAssociacoesPessoais() {
      const consultaEncontrada = this.resultados.Consultas.find((consulta) => {
        return (
          consulta.Consulta &&
          consulta.Consulta.atributos &&
          consulta.Consulta.atributos.descricao.includes("Associações Pessoais")
        );
      });

      return consultaEncontrada ? consultaEncontrada.Consulta.atributos : {};
    },

    resultadosGeolocalidades() {
      const consultaEncontrada = this.resultados.Consultas.find((consulta) => {
        return (
          consulta.Consulta &&
          consulta.Consulta.atributos &&
          consulta.Consulta.atributos.descricao.includes("Endereços e geolocalidades")
        );
      });

      return consultaEncontrada ? consultaEncontrada.Consulta.atributos : {};
    },

    resultadosGeolocalidadesParametros() {
      const consultaEncontrada = this.resultados.Consultas.find((consulta) => {
        return (
          consulta.Consulta &&
          consulta.Consulta.atributos &&
          consulta.Consulta.atributos.descricao.includes("Endereços e geolocalidades")
        );
      });

      return consultaEncontrada
        ? consultaEncontrada.Consulta.sumario_consulta.parametros
        : {};
    },

    resultadosMapaRiscos() {
      const consultaEncontrada = this.resultados.Consultas.find((consulta) => {
        return (
          consulta.Consulta &&
          consulta.Consulta.atributos &&
          consulta.Consulta.atributos.descricao.includes("Mapa Geral de Riscos")
        );
      });

      return consultaEncontrada ? consultaEncontrada.Consulta.atributos : {};
    },

    resultadosConexoesEmpresas() {
      const consultaEncontrada = this.resultados.Consultas.find((consulta) => {
        return (
          consulta.Consulta &&
          consulta.Consulta.atributos &&
          consulta.Consulta.atributos.descricao.includes("Conexões com empresas")
        );
      });

      return consultaEncontrada ? consultaEncontrada.Consulta.atributos : {};
    },

    resultadoHistoricoDePassagens() {
      const consultaEncontrada = this.resultados.Consultas.find((consulta) => {
        return (
          consulta.Consulta &&
          consulta.Consulta.atributos &&
          consulta.Consulta.atributos.descricao.includes("Histórico de Passagens")
        );
      });

      return consultaEncontrada ? consultaEncontrada.Consulta.atributos : {};
    },

    resultadosFaturamentoPresumido() {
      return this.resultados.Consultas.filter((consulta) => {
        return (
          consulta.Consulta &&
          consulta.Consulta.sumario_consulta.parametros.cod_ref.startsWith("BOXPRPJ00")
        );
      });
    },

    ...mapWritableState(userPreferenceStore, {
      ocultarDados: "ocultarDados",
      exibirTootipsConsultas: "exibirTootipsConsultas",
      tipoDownloadConsulta: "tipoDownloadConsulta",
    }),

    ...mapWritableState(produtosStore, {
      produtos: "produtos",
    }),

    ...mapWritableState(blocosSuperBoxStore, {
      openBlocos: "openBlocos",
    }),

    ...mapWritableState(chatOutboxStore, {
      liberaIconeChat: "liberaIconeChat",
    }),

    valorCPF() {
      var cpf = mascaras.cpf(
        this.resultados.Consultas[0].Consulta.sumario_consulta.parametros.cpf
      );
      if (this.ocultarDados) {
        return cpf.replace(/(\d{3})\.(\d{3})\.(\d{3})-(\d{2})/, "***.$2.$3-**");
      } else {
        return cpf;
      }
    },

    valorDataRef() {
      var data = this.resultados.Consultas[0].Consulta.sumario_consulta.parametros
        .data_ref;

      return this.formataData(data);
    },

    nomeCompleto() {
      const consultaEncontrada = this.resultados.Consultas.find((consulta) => {
        return (
          consulta.Consulta &&
          consulta.Consulta.atributos &&
          consulta.Consulta.atributos.descricao.includes("Dados Cadastrais")
        );
      });

      var nome = consultaEncontrada.Consulta.atributos.bk_101_001_nome;
      if (this.ocultarDados) {
        let palavras = nome.split(" ");

        if (palavras.length > 1) {
          for (let i = 1; i < palavras.length; i++) {
            palavras[i] = palavras[i].replace(/\S/g, "*");
          }
        }
        return palavras.join(" ");
      } else {
        return nome;
      }
    },

    data() {
      return this.resultados.Consultas[0].Consulta.sumario_consulta.data_transacao_brt
        .slice(0, 10)
        .split("-")
        .reverse()
        .join("/");
    },

    hora() {
      return this.resultados.Consultas[0].Consulta.sumario_consulta.data_transacao_brt.slice(
        11,
        19
      );
    },

    tooltipsBloco0() {
      return listaTooltips.inicio;
    },

    listaBlocos() {
      return produtosSuperBox.superBox.Blocos.filter((item) => !item.box.includes("_"));
    },
  },

  async mounted() {
    this.liberaSpinner = true;

    this.controlaBlocos();
    this.ocultarDados = await this.syncPreferenciaOcultarDados();
    this.exibirTootipsConsultas = await this.syncPreferenciaConsultasTooltips();
    this.tipoDownloadConsulta = await this.syncPreferenciaTipoDownload();

    this.initRefsList();
    this.initObserver();

    this.liberaIconeChat = true;
  },

  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect();
    }
    this.liberaIconeChat = false;
  },

  async beforeRouteLeave(to, from, next) {
    this.liberaIconeChat = false;
  },
};
</script>

<style lang="scss" scoped>
.boxResult {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  align-items: center;

  @media (max-width: 960px) {
    overflow: hidden;
    overflow-x: scroll;
    align-items: flex-start;
  }
}

.box-button {
  width: 100%;
  max-width: 960px;
  display: flex;
  justify-content: flex-end;
  position: relative;
  top: 10px;
}

.pdfButton {
  width: 130px;
  height: 35px;
  font-size: 12px;
  padding: 1px 5px;
  color: white;
  border: none;
  background-image: url(/img/tela-inicial/Botao-Nova.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  line-height: 14px;
  margin: 0px 5px;
  cursor: pointer;
  transition: 0.5s;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    transform: scale(1.05);
  }
  &:active {
    transform: scale(0.95);
  }
}
.contentFull {
  width: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 960px) {
    align-items: flex-start;
    max-width: 100%;
    overflow-x: scroll;
  }
}

.header {
  display: flex;
  width: 910px;
  height: 80px;
  background-color: transparent;
  background-image: url(/img/superbox/Topo_SUPERBOX.png);
  background-repeat: no-repeat;
  background-size: contain;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  position: absolute;
  top: 120px;
}

.header-title {
  margin: 0 0 5px 0 !important;
  font-weight: 500;
  font-size: 36px;
  color: #4356a5;
}

.body {
  height: 1100px;
  width: 950px;
  margin: 2% auto;
  background-color: transparent;
  background-image: url(/img/superbox/fundo.png);
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border-radius: 10px;
}

.cracha {
  background-color: transparent;
  background-image: url(/img/superbox/Cracha.png);
  background-repeat: no-repeat;
  background-size: cover;
  width: 437px;
  height: 295px;
  padding: 27px 10px 10px 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-end;
}
.img-logo {
  height: 80%;
}
.img-logo-x {
  height: 120px;
  position: absolute;
  top: 150px;
  right: 76px;
  opacity: 0.2;
}
.col {
  width: 50%;
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.info-cracha {
  margin: 5px 5px 5px 15px;
  display: flex;
  flex-direction: column;
  cursor: default;
  z-index: 2;

  &.data_ref {
    margin: 0px 5px 5px 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    flex-direction: row;
    gap: 5px;
  }
}
.info-title {
  color: white;
  font-weight: 600;
  font-size: 14px;
  line-height: 18px;

  &.data_ref {
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }
}
.info-res {
  color: white;
  font-weight: 300;
}
.info-blocos {
  display: grid;
  justify-content: center;
  grid-template-columns: 0.1fr 0.1fr 0.1fr;
  margin: 35px 0 5px 0;
}

.spinnerBG {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 125%;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 99;
}

.spinner {
  position: fixed;
  top: 40%;
  left: 52%;
  transform: scale(1.2);
  z-index: 10;
}

.footer-sup {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 960px;
  margin: 5px 0;
  cursor: default;
}

.footer-text {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  display: flex;
  align-items: center;
  z-index: 10;
  gap: 5px;

  .link {
    color: #0070c0;
    cursor: pointer;

    &:hover {
      font-weight: 500;
    }
  }
}

.question {
  border: 2px solid #0070c0c9;
  color: #0070c0c9;
  border-radius: 50%;
  padding: 2px;
  transition: 0.3s;
  font-size: 16px;
}
</style>
