<template>
  <div class="box-valor momento">
    <div class="itens-linha">
      <span
        v-for="item in itens"
        :key="item"
        class="item"
        :class="{ selecionado: momento == item, hover: hoverItem == item }"
        @mouseover="handleMouseOver(item)"
        @mouseleave="hoverItem = ''"
      >
        {{ getPorcentagem(item) }}
      </span>
    </div>
    <div
      class="valor-momento"
      :class="{
        hover: hoverItem != '',
        vazio: momento == '-' || momento == '',
      }"
    >
      {{ hoverItem ? hoverItem : momento }}
    </div>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapWritableState, mapActions } from "pinia";

export default {
  name: "MomentoVida",

  components: {},

  props: {
    tipoExibido: String,
    resultados: Object,
  },

  data() {
    return {
      itens: [
        "Estudante",
        "Assalariado",
        "Estável",
        "Autônomo",
        "Empreendedor",
        "Aposentado",
      ],
      hoverItem: "",

      tipoExibidoDelay: this.tipoExibido,
    };
  },

  watch: {
    tipoExibido(newVal, oldVal) {
      if (newVal !== oldVal) {
        setTimeout(() => {
          this.tipoExibidoDelay = newVal;
        }, 500);
      }
    },
  },

  methods: {
    getPorcentagem(tipo) {
      var tipoTratado = tipo
        .normalize("NFD")
        .replace(/[\u0300-\u036f]/g, "")
        .toLowerCase();
      if (this.tipoExibidoDelay != "documento") {
        const atributo = `bk_111_001_num_${
          this.tipoExibidoDelay
        }_provavel_momento_vida_proporcao_${tipoTratado.toLowerCase()}`;
        return this.resultados[atributo] != "-"
          ? this.resultados[atributo] + "%"
          : this.resultados[atributo];
      } else {
        return null;
      }
    },

    handleMouseOver(item) {
      if (this.momento !== item) {
        this.hoverItem = item;
      }
    },
  },

  created() {},

  computed: {
    momento() {
      if (this.tipoExibidoDelay == "documento") {
        var result =
          this.resultados[
            `boxauxsuppf002_class_documento_provavel_momento_vida`
          ];
      } else {
        var result =
          this.resultados[
            `bk_111_001_class_${this.tipoExibidoDelay}_provavel_momento_vida`
          ];
      }

      const substitutions = {
        Estavel: "Estável",
        Autonomo: "Autônomo",
      };

      if (substitutions[result]) {
        result = substitutions[result];
      }
      return result;
    },
  },

  mounted() {},
};
</script>

<style lang="scss" scoped>
.box-valor {
  padding: 5px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 18px;
  border-radius: 0 0 10px 10px;
  z-index: -1;
  background-color: white;
  max-height: 95px;
  cursor: pointer;

  &.momento {
    flex: 1;
    align-items: center;
    justify-content: center;
  }
}

.itens-linha {
  display: flex;
  gap: 1px;

  .item {
    display: flex;
    border-radius: 40%;
    width: 32px;
    height: 40px;
    background-color: #d9d9d9;
    justify-content: center;
    align-items: flex-start;
    padding: 8px 0 0 0;
    transition: background-color 0.4s, color 0.4s;
    font-size: 11px;

    &.selecionado {
      background: linear-gradient(
        225deg,
        rgba(91, 84, 153, 1) 0%,
        rgba(134, 123, 221, 1) 50%,
        rgba(160, 148, 255, 1) 100%
      );
      color: white;
    }

    &:hover {
      background-color: #b8b8b8;
    }
  }
}

.valor-momento {
  background: linear-gradient(
    225deg,
    rgba(91, 84, 153, 1) 0%,
    rgba(134, 123, 221, 1) 50%,
    rgba(160, 148, 255, 1) 100%
  );
  color: white;
  width: 100%;
  border-radius: 15px;
  position: relative;
  bottom: 10px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;

  &.hover,
  &.vazio {
    background: #d9d9d9;
    color: #212529;
  }
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
