<template>
  <div class="box-valor">
    <span class="valor-linha" v-for="item in itens" :key="item.tipo">
      <span class="texto"> {{ item.titulo }} </span>
      <span class="valor">
        <span class="valor-texto" v-if="tipoExibidoDelay != 'documento'">
          {{ getPorcentagem(item.tipo) }}
        </span>
        <label class="switch disabled">
          <input
            type="checkbox"
            v-model="checkboxStates[item.tipo]"
            class="disabled"
          />
          <span class="slider"></span>
        </label>
      </span>
    </span>
  </div>
</template>

<script>
import { blocosSuperBoxStore } from "@/stores/superbox-blocosControle.js";
import { mapWritableState, mapActions } from "pinia";

export default {
  name: "Associacoes",

  components: {},

  props: {
    tipoExibido: String,
    resultados: Object,
  },

  data() {
    return {
      tipoExibidoDelay: this.tipoExibido,

      itens: [
        { tipo: "assistencia_social", titulo: "Assistência Social" },
        { tipo: "funcionalismo_publico", titulo: "Servidor Federal" },
        { tipo: "perfil_pep", titulo: "Perfil PEP" },
        { tipo: "vinculo_empresas", titulo: "Vínculo com Empresas" },
      ],

      checkboxStates: {},
    };
  },

  watch: {
    tipoExibido(newVal, oldVal) {
      setTimeout(() => {
        this.tipoExibidoDelay = newVal;
        this.atualizaCheckbox();
      }, 500);
    },

    resultados(newValue, oldValue) {
      if (Object.keys(newValue).length !== 0) {
        this.atualizaCheckbox();
      }
    },
  },

  methods: {
    getPorcentagem(tipo) {
      if (Object.keys(this.resultados).length !== 0) {
        if (this.tipoExibidoDelay != "documento") {
          const atributo = `bk_111_001_num_${this.tipoExibidoDelay}_associacao_${tipo}_proporcao`;
          return this.resultados[atributo] != "-"
            ? this.resultados[atributo] + "%"
            : this.resultados[atributo];
        } else {
          return "-";
        }
      }
    },

    getFlag(tipo) {
      if (Object.keys(this.resultados).length !== 0) {
        if (this.tipoExibidoDelay == "documento") {
          var atributo = `boxauxsuppf002_flag_documento_associacao_${tipo}`;
        } else {
          var atributo = `bk_111_001_flag_${this.tipoExibidoDelay}_associacao_${tipo}`;
        }

        if (
          this.resultados[atributo] == null ||
          this.resultados[atributo] == "-"
        ) {
          return false;
        } else {
          return this.resultados[atributo];
        }
      }
    },

    atualizaCheckbox() {
      this.itens.forEach((item) => {
        this.$set(this.checkboxStates, item.tipo, this.getFlag(item.tipo));
      });
    },
  },

  created() {},

  computed: {},

  mounted() {
    this.atualizaCheckbox();
  },
};
</script>

<style lang="scss" scoped>
.box-valor {
  padding: 5px 10px 0 10px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  line-height: 18px;
  border-radius: 0 0 10px 10px;
  z-index: -1;
  background-color: white;
  max-height: 95px;
  cursor: pointer;
}

.valor-linha {
  text-align: start;
  display: flex;
  justify-content: space-between;
  line-height: 11px;
  height: 22px;
  display: flex;
  align-items: center;

  &.assoc {
    margin-top: 4px;
  }

  .valor {
    display: flex;
    gap: 10px;
    align-items: center;
  }
}

.valor-texto {
  font-size: 11px;
}

.valor-switch {
  margin: 0;
}

.switch {
  position: relative;
  display: inline-block;
  width: 36px;
  height: 16px;
  margin: 0;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 10px;
  width: 10px;
  left: 5px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background: linear-gradient(
    225deg,
    rgba(91, 84, 153, 1) 0%,
    rgba(134, 123, 221, 1) 50%,
    rgba(160, 148, 255, 1) 100%
  );
}

input:checked + .slider:before {
  transform: translateX(16px);
}

.disabled {
  pointer-events: none;
}
</style>

<style src="@/styles/components/superbox_customizado.scss" scoped lang="scss" />
